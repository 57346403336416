/* Base styles (Mobile First) */
:root {
  --primary-color: #5C7CFA;
  --primary-light: #748FFC;
  --primary-dark: #4263EB;
  --secondary-color: #329AF0;
  --accent-color: #22B8CF;
  --text-color: #212529;
  --text-light: #495057;
  --text-muted: #6c757d;
  --background-color: #FFFFFF;
  --background-light: #F8F9FA;
  --background-dark: #E9ECEF;
  --border-color: #DEE2E6;
  --card-background: #FFFFFF;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --success-color: #51CF66;
  --warning-color: #FFD43B;
  --error-color: #FF6B6B;
  --header-color: #1a1c32;
  --sidebar-width: 220px; /* Reduced from 260px to 220px */
  --nav-icon-size: 32px; /* Slightly smaller */
  --header-height: 60px;
  --content-max-width: 1200px; /* Increased from 940px to 1200px */
  --button-text-light: #333333; /* Dark gray text color for buttons in light mode */
  --icon-color-light: #333333; /* Dark gray icon color for light mode */
  --icon-color-dark: #F8F9FA; /* Light icon color for dark mode */
  --active-icon-color-light: #4c6ef5; /* Blue active icon color for light mode */
  --active-icon-color-dark: #748FFC; /* Light blue active icon color for dark mode */
}

/* Dark mode variables */
[data-mantine-color-scheme="dark"] {
  --primary-color: #748FFC;
  --primary-light: #91A7FF;
  --primary-dark: #5C7CFA;
  --secondary-color: #4DABF7;
  --accent-color: #3BC9DB;
  --text-color: #F8F9FA;
  --text-light: #DEE2E6;
  --background-color: #212529;
  --background-light: #343A40;
  --background-dark: #1A1D20;
  --border-color: #495057;
  --card-background: #2C3038;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --success-color: #69DB7C;
  --warning-color: #FFE066;
  --error-color: #FF8787;
  --header-color: #F8F9FA; /* Updated to light color for dark mode */
  color-scheme: dark;
  --button-text-light: #F8F9FA; /* Light text color for buttons in dark mode */
  --icon-color-light: #F8F9FA; /* Light icon color for dark mode */
  --icon-color-dark: #F8F9FA; /* Light icon color for dark mode */
  --active-icon-color-light: #748FFC; /* Light blue active icon color for dark mode */
  --active-icon-color-dark: #748FFC; /* Light blue active icon color for dark mode */
}

/* Reset & Base */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--background-color);
  overflow-x: hidden; /* Prevent horizontal scrolling */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* AppShell Styles */
.mantine-AppShell-main {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0 !important;
  padding-top: 70px !important; /* Add top padding to push content below header */
  overflow-x: hidden;
  background-color: var(--background-color);
  border: none !important;
  transition: background-color 0.3s ease, margin-left 0.3s ease, width 0.3s ease;
  /*margin-top: 60px !important;  Add margin to account for fixed header - keep this exact value */
  min-height: calc(100vh - 70px) !important;
  background-image: 
    linear-gradient(rgba(236, 237, 253, 0.3) 1px, transparent 1px),
    linear-gradient(90deg, rgba(236, 237, 253, 0.3) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: center center;
}

/* Dark mode background pattern */
[data-mantine-color-scheme="dark"] .mantine-AppShell-main {
  background-image: 
    linear-gradient(rgba(92, 124, 250, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(92, 124, 250, 0.05) 1px, transparent 1px);
}

/* Navbar Styles */
.mantine-AppShell-navbar {
  border: none !important;
  background-color: var(--card-background);
  box-shadow: none !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  overflow: visible !important;
}

/* Improve navbar section spacing */
.mantine-Navbar-section {
  padding: 0.5rem 0;
  border-color: var(--border-color);
  transition: border-color 0.3s ease;
}

/* Header Styles */
.mantine-AppShell-header {
  border: none !important;
  background-color: var(--card-background);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) !important;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 16px !important;
  justify-content: space-between !important;
  z-index: 1001 !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important;
}

/* Logo container styles */
.app-logo-container {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

/* Logo text styles */
.app-logo-text {
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  background: linear-gradient(45deg, #6e56cf, #4c6ef5) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  letter-spacing: -0.5px !important;
}

/* Logo icon styles */
.app-logo-icon {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.app-logo-icon svg {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Theme toggle button styles */
.theme-toggle-button {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  min-height: 36px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f0f2f5 !important;
  color: #333 !important;
  border: none !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.2s ease !important;
}

/* Dark mode theme toggle button */
[data-mantine-color-scheme="dark"] .theme-toggle-button {
  background-color: #3E4047 !important;
  color: #f0f2f5 !important;
}

/* Header actions container */
.header-actions {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

/* Desktop header specific styles */
@media (min-width: 769px) {
  /* Theme toggle button - global styles */
  .theme-toggle-button {
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
    color: var(--button-text-light) !important; /* Using the variable for text color */
  border: none !important;
  }
}

/* Header group - global styles */
.header-group {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

/* Desktop header specific styles */
@media (min-width: 769px) {
  .mantine-AppShell-header {
    padding: 0 !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
  }
  
  .header-group {
    justify-content: space-between !important;
  }
  
  .app-logo-text {
    font-size: 1.7rem !important;
    margin-left: 10px !important;
    letter-spacing: -0.5px !important;
  }
  
  .app-logo-icon {
    width: 42px !important;
    height: 42px !important;
    border-radius: 10px !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15) !important;
  }
  
  .header-actions {
    gap: 16px !important;
    justify-content: flex-end !important;
  }
  
  /* Adjust the burger menu position */
  .mantine-Burger-root {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
  
  /* Ensure the logo container is properly positioned */
  .mantine-Group-root.logo-group {
    flex: 0 0 auto !important;
  }
  
  /* Ensure the actions container is properly positioned */
  .mantine-Group-root.header-actions {
    flex: 0 0 auto !important;
  }
  
  .theme-toggle-button {
    width: 42px !important;
    height: 42px !important;
    background-color: rgba(92, 124, 250, 0.08) !important;
    border: none !important;
  }
  
  .theme-toggle-button:hover {
    background-color: rgba(92, 124, 250, 0.15) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* User greeting button styling for desktop */
  .user-greeting-button {
    padding: 8px 16px !important;
    border-radius: 10px !important;
    background-color: rgba(92, 124, 250, 0.08) !important;
    border: none !important;
    transition: all 0.2s ease !important;
    color: var(--button-text-light) !important; /* Using the variable for text color */
  }
  
  .user-greeting-button:hover {
    background-color: rgba(92, 124, 250, 0.15) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Burger menu styling for desktop */
  .mantine-Burger-root {
    margin-right: 10px !important;
    width: 42px !important;
    height: 42px !important;
    border-radius: 10px !important;
    background-color: rgba(92, 124, 250, 0.08) !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: var(--button-text-light) !important; /* Using the variable for text color */
  }
  
  .mantine-Burger-root:hover {
    background-color: rgba(92, 124, 250, 0.15) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Request Access button styling */
  .request-access-button {
    min-width: 140px !important;
    height: 38px !important;
    padding: 0 16px !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    letter-spacing: 0.2px !important;
    background: linear-gradient(45deg, rgba(92, 124, 250, 0.1), rgba(110, 86, 207, 0.1)) !important;
    border: none !important;
    transition: all 0.2s ease !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important;
    background-color: #f0f2f5 !important; /* Light gray background */
    color: var(--button-text-light) !important; /* Using the variable for text color */
  }
  
  .request-access-button .mantine-Button-inner {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  .request-access-button .mantine-Button-label {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 500 !important;
  }
  
  .request-access-button:hover {
    background: linear-gradient(45deg, rgba(92, 124, 250, 0.15), rgba(110, 86, 207, 0.15)) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  [data-mantine-color-scheme="dark"] .request-access-button {
    background: linear-gradient(45deg, rgba(92, 124, 250, 0.15), rgba(110, 86, 207, 0.15)) !important;
    border: none !important;
    background-color: #2C2E33 !important; /* Dark gray background for dark mode */
  }
  
  [data-mantine-color-scheme="dark"] .request-access-button:hover {
    background: linear-gradient(45deg, rgba(92, 124, 250, 0.25), rgba(110, 86, 207, 0.25)) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }
}

/* Dark mode header adjustments for desktop */
@media (min-width: 769px) {
  [data-mantine-color-scheme="dark"] .mantine-AppShell-header {
    background-color: #1A1B1E !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06) !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2) !important;
  }
  
  [data-mantine-color-scheme="dark"] .theme-toggle-button,
  [data-mantine-color-scheme="dark"] .mantine-Burger-root,
  [data-mantine-color-scheme="dark"] .user-greeting-button {
    background-color: rgba(92, 124, 250, 0.1) !important;
    border: none !important;
  }
  
  [data-mantine-color-scheme="dark"] .theme-toggle-button:hover,
  [data-mantine-color-scheme="dark"] .mantine-Burger-root:hover,
  [data-mantine-color-scheme="dark"] .user-greeting-button:hover {
    background-color: rgba(92, 124, 250, 0.2) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  }
}

/* Mobile specific styles */
@media (max-width: 768px) {
  /* Mobile header specific styles */
  .mantine-AppShell-header {
    padding: 0 12px !important;
  }
  
  .app-logo-icon {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    border-radius: 8px !important;
  }
  
  .app-logo-icon svg {
    width: 18px !important;
    height: 18px !important;
  }
  
  .app-logo-text {
    font-size: 1.1rem !important;
  }
  
  /* Make sure the theme toggle button is circular in mobile view, just like desktop */
  .theme-toggle-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  [data-mantine-color-scheme="dark"] .theme-toggle-button {
    background-color: #3E4047 !important;
    color: #f0f2f5 !important;
    border-radius: 50% !important;
    overflow: hidden !important;
  }
  
  /* Make the request access button circular like in desktop */
  .request-access-button {
    background-color: #4c6ef5 !important;
    color: white !important;
    border-radius: 50% !important;
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    overflow: hidden !important;
  }
  
  .request-access-button .mantine-Button-leftIcon {
    margin-right: 0 !important;
    margin: 0 auto !important;
  }
  
  .request-access-button .mantine-Button-leftIcon svg {
    width: 18px !important;
    height: 18px !important;
    color: white !important;
    display: block !important;
  }
  
  .header-actions {
    margin-right: 8px !important;
  }
  
  .theme-toggle-button {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Make the Request Access button smaller on mobile */
  .request-access-button {
    padding: 6px 10px !important;
    height: 36px !important;
    min-height: 36px !important;
    min-width: 40px !important;
    width: auto !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .request-access-button .mantine-Button-inner {
    justify-content: center !important;
  }
  
  .request-access-button .mantine-Button-leftIcon {
    margin-right: 0 !important;
  }
  
  .request-access-button .mantine-Button-leftIcon svg {
    width: 18px !important;
    height: 18px !important;
    display: block !important;
  }
  
  /* Adjust logo container spacing */
  .app-logo-container {
    gap: 4px !important;
    padding-left: 6px !important;
  }
  
  /* Ensure the header group has proper spacing */
  .header-group {
    padding: 0 !important;
  }
  
  /* Remove borders and padding from main content area */
  .content-container {
    padding: 0 !important;
    margin: 0 !important;
    padding-top: 8px !important; /* Add some padding at the top for spacing */
  }
  
  /* Remove borders and padding from cards and papers */
  .mantine-Paper-root {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Remove borders and padding from bottom nav */
  .mobile-bottom-nav {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Force the navbar to display at the bottom */
  .mobile-bottom-nav {
    position: fixed !important;
    bottom: 0 !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 70px !important;
    min-height: 70px !important;
    max-height: 70px !important;
    z-index: 9999 !important;
    background-color: #f0f2f5 !important; /* Light gray background instead of white */
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: none !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    overflow: visible !important;
  }

  /* Override any AppShell navbar positioning */
  .mantine-AppShell-navbar.mobile-bottom-nav {
    position: fixed !important;
    bottom: 0 !important;
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 70px !important;
    min-height: 70px !important;
    overflow: visible !important;
  }

  /* Hide the default navbar in mobile view */
  .mantine-AppShell-navbar:not(.mobile-bottom-nav) {
    display: none !important;
  }

  /* Ensure the mobile navbar is visible */
  .mantine-AppShell-navbar.mobile-bottom-nav {
    display: flex !important;
  }

  /* Hide dividers and footers in mobile navbar */
  .mobile-bottom-nav .nav-divider,
  .mobile-bottom-nav .nav-footer {
    display: none !important;
  }

  /* Add spacing to main content to prevent overlap */
  .mantine-AppShell-main {
    padding-bottom: 90px !important;
  }

  /* Fix for iOS devices with home indicator */
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    .mobile-bottom-nav {
      padding-bottom: env(safe-area-inset-bottom) !important;
      height: calc(70px + env(safe-area-inset-bottom)) !important;
    }
    
    .mantine-AppShell-main {
      padding-bottom: calc(90px + env(safe-area-inset-bottom)) !important;
    }
  }

  /* Force the navbar items to go horizontal with proper spacing */
  .mobile-bottom-nav .nav-items-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    overflow: visible !important;
  }

  /* Style nav items */
  .mobile-bottom-nav .nav-item,
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 !important;
    max-width: 25% !important;
    position: relative !important;
    height: 100% !important;
    box-sizing: border-box !important;
    overflow: visible !important;
  }
  
  /* Center the icons perfectly */
  .mobile-bottom-nav .nav-items-container > * {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 25% !important;
  }
  
  /* Ensure equal spacing between icons */
  .mobile-bottom-nav .nav-items-container {
    gap: 0 !important;
    padding: 0 10px !important;
  }

  /* Improved icon styling for mobile navigation */
  .mobile-bottom-nav .mantine-ThemeIcon-root {
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    max-width: 42px !important;
    max-height: 42px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 0 !important;
    box-sizing: border-box !important;
    overflow: visible !important;
    background-color: #e4e6eb !important; /* Lighter gray for the buttons */
    border: none !important;
    transition: all 0.2s ease !important;
    position: relative !important;
    transform-origin: center !important;
    z-index: 1 !important;
    color: var(--icon-color-light) !important; /* Using the variable for icon color */
  }

  /* Icon sizing and positioning */
  .mobile-bottom-nav .mantine-ThemeIcon-root svg {
    width: 22px !important;
    height: 22px !important;
    display: block !important;
    margin: 0 auto !important;
    padding: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 2 !important;
    color: var(--icon-color-light) !important; /* Using the variable for icon color */
  }

  /* Active state styling */
  .mobile-bottom-nav .mantine-UnstyledButton-root.active .mantine-ThemeIcon-root {
    background-color: rgba(236, 237, 253, 0.15) !important;
    border: none !important;
    transform: scale(1.05) !important;
    box-shadow: 0 2px 4px rgba(92, 124, 250, 0.1) !important;
  }
  
  /* Active icon color */
  .mobile-bottom-nav .mantine-UnstyledButton-root.active .mantine-ThemeIcon-root svg {
    color: var(--active-icon-color-light) !important; /* Using the variable for active icon color */
  }

  /* Always hide text in mobile navigation */
  .mobile-bottom-nav .mantine-Text-root {
    display: none !important;
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Dark mode adjustments */
  [data-mantine-color-scheme="dark"] .mobile-bottom-nav {
    background-color: #2C2E33 !important; /* Dark gray background instead of default dark theme color */
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  }

  [data-mantine-color-scheme="dark"] .mobile-bottom-nav .mantine-ThemeIcon-root {
    background-color: #3E4047 !important; /* Slightly lighter gray for dark mode buttons */
    border: none !important;
    color: var(--icon-color-dark) !important; /* Using the variable for dark mode icon color */
  }

  [data-mantine-color-scheme="dark"] .mobile-bottom-nav .mantine-UnstyledButton-root.active .mantine-ThemeIcon-root {
    background-color: rgba(92, 124, 250, 0.2) !important;
    border: none !important;
    box-shadow: 0 2px 4px rgba(92, 124, 250, 0.2) !important;
  }
  
  [data-mantine-color-scheme="dark"] .mobile-bottom-nav .mantine-UnstyledButton-root.active .mantine-ThemeIcon-root svg {
    color: var(--active-icon-color-dark) !important; /* Using the variable for dark mode active icon color */
  }

  /* Special styling for menu items with submenus */
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root {
    position: relative !important;
  }
  
  /* Add subtle indicator for buttons with submenus */
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root::after {
    content: "" !important;
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    width: 6px !important;
    height: 6px !important;
    background-color: #4c6ef5 !important;
    border-radius: 50% !important;
    opacity: 0.8 !important;
  }
  
  /* Make ThemeIcons for submenu items more prominent */
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root .mantine-ThemeIcon-root {
    background-color: rgba(236, 237, 253, 0.08) !important;
    border: none !important;
  }
  
  /* Active state for menu items with submenus */
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root.active .mantine-ThemeIcon-root {
    background-color: rgba(236, 237, 253, 0.15) !important;
    border: none !important;
    box-shadow: 0 2px 4px rgba(92, 124, 250, 0.2) !important;
  }
  
  /* Adjust the icon size to be slightly larger for submenu buttons */
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root .mantine-ThemeIcon-root {
    width: 42px !important;
    height: 42px !important;
    min-width: 42px !important;
    min-height: 42px !important;
    max-width: 42px !important;
    max-height: 42px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  /* Accordion menu styling for mobile */
  .accordion-menu-item {
    max-width: 100%;
  }
  
  .accordion-menu-subitem {
    margin-left: 15px;
    padding-left: 0;
  }
  
  /* More touch-friendly on mobile */
  .accordion-menu-subitem .mantine-UnstyledButton-root {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin-bottom: 8px !important;
  }
  
  /* Add explicit touch target for accordion items */
  .mantine-AppShell-navbar .accordion-menu-item > .mantine-UnstyledButton-root {
    padding: 10px 12px !important;
    min-height: 44px !important; /* Apple's recommended min touch target size */
    position: relative;
    z-index: 10;
  }
  
  /* Make the text smaller on mobile */
  .accordion-menu-subitem .mantine-Text-root {
    font-size: 0.9rem;
  }
  
  /* Reduce size of "Login required" text */
  .accordion-menu-subitem .mantine-Text-root + .mantine-Text-root {
    font-size: 0.75rem;
    opacity: 0.8;
  }
  
  /* Active state for mobile touches */
  .mantine-AppShell-navbar .accordion-menu-item > .mantine-UnstyledButton-root:active {
    background-color: var(--mantine-color-gray-2) !important;
  }
  
  /* Highlight the clickable area of the chevron */
  .mantine-AppShell-navbar .accordion-menu-item > .mantine-UnstyledButton-root > *:last-child {
    padding: 10px !important;
    margin: -10px 0 -10px -10px !important;
  }

  /* Mobile-specific overrides to remove any indicators */
  .mobile-bottom-nav .nav-item::before,
  .mobile-bottom-nav .nav-item::after,
  .mobile-bottom-nav .login-nav-item::before,
  .mobile-bottom-nav .login-nav-item::after,
  .mobile-bottom-nav .mantine-UnstyledButton-root::before,
  .mobile-bottom-nav .mantine-UnstyledButton-root::after {
    display: none !important;
    content: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: hidden !important;
    border: none !important;
    background: none !important;
  }
  
  /* Ensure no borders or outlines on mobile nav items */
  .mobile-bottom-nav .nav-item,
  .mobile-bottom-nav .login-nav-item {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  
  /* Remove hover background on mobile nav items */
  .mobile-bottom-nav .nav-item:hover,
  .mobile-bottom-nav .login-nav-item:hover,
  .mobile-bottom-nav .nav-item:active,
  .mobile-bottom-nav .login-nav-item:active,
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root:hover,
  .mobile-bottom-nav .accordion-menu-item > .mantine-UnstyledButton-root:active {
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
  }

  /* Remove all borders and shadows from mobile view */
  .mantine-Card-root,
  .mantine-Paper-root,
  .mantine-Button-root,
  .mantine-ActionIcon-root,
  .mantine-ThemeIcon-root,
  .mantine-TextInput-root,
  .mantine-Textarea-root,
  .mantine-Select-root,
  .mantine-Tabs-root,
  .mantine-Tabs-tab,
  .mantine-Tabs-panel {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Ensure no padding in mobile content */
  .mantine-Tabs-panel,
  .mantine-Card-root,
  .mantine-Paper-root {
    padding: 8px !important;
    margin: 0 !important;
  }
  
  /* Remove border radius in mobile view */
  .mantine-Card-root,
  .mantine-Paper-root,
  .mantine-Button-root,
  .mantine-ActionIcon-root,
  .mantine-ThemeIcon-root,
  .mantine-TextInput-root,
  .mantine-Textarea-root,
  .mantine-Select-root {
    border-radius: 0 !important;
  }
  
  /* Ensure content takes full width in mobile */
  .content-container,
  .mantine-Card-root,
  .mantine-Paper-root {
    width: 100% !important;
    max-width: 100% !important;
  }
  
  /* Remove any margin from titles */
  .mantine-Title-root {
    margin: 8px 0 !important;
  }
}

/* Mobile header background color to match buttons - wider breakpoint */
@media (max-width: 750px) {
  .mantine-AppShell-header {
    background-color: #f0f2f5 !important;
  }
  
  [data-mantine-color-scheme="dark"] .mantine-AppShell-header {
    background-color: #3E4047 !important;
  }
}

/* Extra small mobile devices */
@media (max-width: 480px) {
  .mantine-AppShell-header {
    background-color: var(--button-background) !important;
  }
  
  [data-mantine-color-scheme="dark"] .mantine-AppShell-header {
    background-color: #3E4047 !important;
  }
  
  [data-mantine-color-scheme="light"] .mantine-AppShell-header {
    background-color: #f0f2f5 !important;
  }
}

/* Navigation specific styling - DESKTOP STYLES */
.mantine-UnstyledButton-root {
  position: relative;
  overflow: visible !important;
  display: flex !important;
  align-items: center !important;
  white-space: nowrap;
  border-left: none !important;
}

/* Remove any potential Mantine 7 indicators */
.mantine-UnstyledButton-root::before,
.mantine-UnstyledButton-root::after {
  display: none !important;
  content: none !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  border: none !important;
  background: none !important;
}

/* Specifically target the login button */
.login-nav-item,
.nav-item[class*="Login"] {
  border-left: none !important;
  position: relative !important;
  overflow: visible !important;
}

.login-nav-item::before,
.login-nav-item::after,
.nav-item[class*="Login"]::before,
.nav-item[class*="Login"]::after {
  display: none !important;
  content: none !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  border: none !important;
  background: none !important;
  position: static !important;
}

/* Ensure no pseudo-elements are visible for navigation items */
.desktop-navbar .nav-item,
.desktop-navbar .login-nav-item {
  position: relative !important;
  overflow: visible !important;
  border-left: none !important;
}

/* Override any Mantine default styling for navigation items */
.mantine-UnstyledButton-root:not(.accordion-menu-subitem .mantine-UnstyledButton-root)::after {
  content: none !important;
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
}

.mantine-UnstyledButton-root:not(.accordion-menu-subitem .mantine-UnstyledButton-root):hover::after,
.mantine-UnstyledButton-root:not(.accordion-menu-subitem .mantine-UnstyledButton-root).active::after {
  height: 0 !important;
  opacity: 0 !important;
  display: none !important;
}

/* Exclude the login/logout button from having the blue line indicator */
.nav-footer .mantine-UnstyledButton-root::after {
  display: none !important;
}

.nav-footer .mantine-UnstyledButton-root:hover::after,
.nav-footer .mantine-UnstyledButton-root.active::after {
  display: none !important;
  height: 0 !important;
  opacity: 0 !important;
}

/* Also remove the blue line indicator from the Login nav item */
.nav-item[class*="Login"]::after,
.nav-item[class*="Login"]:hover::after,
.nav-item[class*="Login"].active::after,
.login-nav-item::after,
.login-nav-item:hover::after,
.login-nav-item.active::after {
  display: none !important;
  height: 0 !important;
  opacity: 0 !important;
}

/* Enhance icon styles */
.mantine-ThemeIcon-root {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.mantine-UnstyledButton-root:hover .mantine-ThemeIcon-root {
  transform: scale(1.05);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* Improve theming for active menu items */
.mantine-Menu-item[data-active] {
  background-color: var(--primary-light);
  color: var(--primary-color);
}

/* Section titles in navigation */
.nav-section-title {
  letter-spacing: 1.2px;
  font-size: 11px;
  font-weight: 600;
  color: var(--text-muted);
  display: block;
  margin-bottom: 12px;
}

/* Accordion menu styling */
.accordion-menu-item {
  transition: all 0.2s ease;
  max-width: 240px;
  position: relative;
  margin-bottom: -2px !important; /* Negative margin to pull items closer */
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Target all main menu items */
.mantine-AppShell-navbar .mantine-UnstyledButton-root {
  margin-bottom: -2px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.accordion-menu-subitem {
  margin-left: 15px;
  padding-left: 0;
  position: relative;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  min-width: 180px;
}

/* Specific styling for submenu buttons */
.accordion-menu-subitem .mantine-UnstyledButton-root {
  transition: background-color 0.15s ease;
  overflow: visible;
  width: 100%;
  padding-left: 25px !important;
  padding-right: 15px !important;
  display: block !important;
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-origin: border-box;
  margin-bottom: 5px !important;  /* Increased spacing between items */
  padding-top: 5px !important;    /* More vertical padding */
  padding-bottom: 8px !important; /* More vertical padding */
}

/* Last submenu item shouldn't have bottom margin */
.accordion-menu-subitem .mantine-UnstyledButton-root:last-child {
  margin-bottom: 0 !important;
}

.accordion-menu-subitem .mantine-UnstyledButton-root:hover {
  background-color: var(--mantine-color-gray-1);
}

.accordion-menu-subitem .mantine-UnstyledButton-root:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1px;
  width: 15px;
  height: 100%;
  background-color: var(--mantine-color-gray-1);
}

.accordion-menu-subitem::before {
  content: '';
  position: absolute;
  left: 9px; /* Changed from 9px to 3px to move it further to the left */
  top: -0px; /* Ex9end slightly above to connect with parent */
  bottom: 0;
  width: 1.5px;
  background-color: var(--mantine-color-gray-4);
  height: calc(100% + 10px);
  z-index: 2;
}

/* Card and list styling */
.mantine-Card-section {
  overflow: hidden;
  position: relative;
}

.mantine-Card-section .mantine-Image-image {
  width: 100%;
  height: 240px; /* Increased from 200px to 240px (20% bigger) */
  object-fit: contain; /* Changed from cover to contain to show the entire image */
  object-position: center; /* Changed from top to center to center the image */
  background-color: rgba(0, 0, 0, 0.03); /* Light background to make image boundaries visible */
  transition: transform 0.3s ease;
  border-top-left-radius: 8px; /* Match card's top-left corner */
  border-top-right-radius: 8px; /* Match card's top-right corner */
}

.mantine-Card-section .mantine-Image-image:hover {
  transform: scale(1.05);
}

/* Fallback for images that fail to load */
.mantine-Card-section .mantine-Image-imageError {
  min-height: 160px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-style: italic;
}

.mantine-Card-section .mantine-Image-imageError::after {
  content: 'Image not available';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Article ordered and unordered lists */
.article-content ol,
.article-content ul {
  margin: 1rem 0 1.5rem 1.5rem;
  padding-left: 1rem;
}

.article-content li {
  margin-bottom: 0.5rem;
  line-height: 1.8;
}

.article-content ol {
  list-style-type: decimal;
}

.article-content ul {
  list-style-type: disc;
}

/* Handle nested lists */
.article-content li > ol,
.article-content li > ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Definition lists for the pattern of list item with description */
.article-content dl {
  margin: 1rem 0 1.5rem 0;
}

.article-content dt {
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.article-content dd {
  margin-left: 2rem;
  margin-bottom: 0.8rem;
  line-height: 1.6;
}

/* Feature items for the heading with description format */
.feature-item {
  margin: 1.5rem 0;
  padding: 0.8rem 1rem;
  border-left: 3px solid #7950f2;
  background-color: #f8f9fa;
  border-radius: 0 4px 4px 0;
}

.feature-item h4 {
  font-size: 1.1rem;
  margin: 0 0 0.5rem 0;
  color: #444;
  font-weight: 600;
}

.feature-item p {
  margin: 0.3rem 0 0 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-indent: 0 !important;
  padding: 0 !important;
}

/* Responsive styles for feature items */
@media (max-width: 768px) {
  .feature-item {
    padding: 0.6rem 0.8rem;
    margin: 1rem 0;
  }
}

/* Global content containers for optimal reading width */
.content-container {
  position: relative;
  padding: 0 16px 8px 16px; /* Reduce top padding to 0 */
  height: 100%;
  min-height: calc(100vh - 70px);
  transition: padding 0.3s ease;
  margin-top: 0; /* Ensure no extra margin at the top */
}

/* Reduce top margin for all page titles */
.content-container > .mantine-Title-root:first-child {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
}

/* Adjust spacing for all components that might be at the top of content areas */
.content-container > *:first-child {
  margin-top: 8px !important;
}

.content-narrow {
  max-width: 800px !important; /* Increased from 65ch to 800px */
}

.content-medium {
  max-width: 1000px !important; /* Increased from 85ch to 1000px */
}

/* Apply readable width limits to common text elements */
.article-content p, 
.article-content li, 
.article-content blockquote {
  max-width: 100%; /* Allow content to use full width of container */
  margin-left: auto;
  margin-right: auto;
}

/* Tabs.Panel specific constraint for article view */
.mantine-Tabs-panel[value="article_detail"] {
  max-width: 100%;
  overflow: hidden;
}

/* Make the article detail view specific constraints */
.mantine-Tabs-panel[value="article_detail"] .content-container {
  max-width: 1000px !important; /* Increased from 700px to 1000px */
}

/* Section titles */
.section-title {
  position: relative;
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 8px !important; /* Ensure minimal top margin */
  margin-bottom: 1.5rem;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.section-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 3rem;
  height: 0.25rem;
  /* background-color: var(--mantine-color-blue-6); */
  border-radius: 1rem;
}

/* Article list specific section title */
.article-list-container .section-title {
  margin-top: 0 !important;
  padding-top: 16px !important;
  margin-bottom: 16px !important;
}

/* Article card styling */
.article-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px !important;
  overflow: hidden !important;
  will-change: transform;
  cursor: pointer;
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
}

.article-card:active {
  transform: translateY(-2px);
  transition: transform 0.1s ease;
}

[data-mantine-color-scheme="dark"] .article-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
}

[data-mantine-color-scheme="dark"] .article-card .mantine-Card-section img,
[data-mantine-color-scheme="dark"] .article-card .mantine-Card-section,
[data-mantine-color-scheme="dark"] .mantine-Card-section .mantine-Image-image {
  background-color: rgba(255, 255, 255, 0.05) !important; /* Lighter background in dark mode */
}

/* Add a subtle shadow to the images */
.article-card .mantine-Card-section img:hover,
.mantine-Card-section .mantine-Image-image:hover,
.article-card .mantine-Card-section > div > img:hover {
  transform: scale(1.1); /* Reduced from 1.05 to 1.02 for a more subtle effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure the card content has proper spacing */
.article-card > div {
  padding: 16px !important;
}

/* Ensure the title has proper spacing */
.article-title {
  font-weight: 600;
  line-height: 1.3;
  color: var(--header-color);
  font-size: 1.1rem;
  margin-bottom: 8px;
  margin-top: 0;
}

.article-summary {
  color: var(--text-light);
  line-height: 1.5;
  flex-grow: 1;
  margin-bottom: 12px;
}

.article-date {
  font-size: 0.75rem;
  color: var(--text-muted);
}

/* Card section with image */
.article-card .mantine-Card-section:first-child {
  height: 240px; /* Increased from 200px to 240px (20% bigger) */
  overflow: hidden !important;
  border-top-left-radius: 8px !important; /* Match card's top-left corner */
  border-top-right-radius: 8px !important; /* Match card's top-right corner */
}

.article-card .mantine-Card-section img {
  width: 100%;
  height: 240px; /* Increased from 200px to 240px (20% bigger) */
  object-fit: contain;
  object-position: center;
  background-color: rgba(0, 0, 0, 0.03);
  border-top-left-radius: 8px !important; /* Match card's top-left corner */
  border-top-right-radius: 8px !important; /* Match card's top-right corner */
}

/* Ensure Card.Section properly handles the border radius */
.article-card .mantine-Card-section:first-child > div {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  overflow: hidden !important;
}

/* Target all possible elements in the image hierarchy */
.article-card .mantine-Card-section .mantine-Image-root,
.article-card .mantine-Card-section .mantine-Image-figure,
.article-card .mantine-Card-section .mantine-Image-imageWrapper,
.article-card .mantine-Card-section .mantine-Image-placeholder,
.article-card .mantine-Card-section .mantine-Image-image {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  overflow: hidden !important;
}

/* Target the Card component itself */
.article-card.mantine-Card-root {
  border-radius: 8px !important;
  overflow: hidden !important;
}

/* Target the gray background of the image container */
.article-card .mantine-Card-section:first-child::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  z-index: -1;
}

/* Ensure consistent card heights in the grid */
.article-list-container .mantine-SimpleGrid-root {
  align-items: stretch;
}

.article-list-container .mantine-SimpleGrid-col {
  display: flex;
  height: 100%;
}

/* Empty state styling */
.empty-state-container {
  padding: 2rem;
  text-align: center;
  background-color: #fff;
}

.empty-state-icon {
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

/* Badge styling */
.mantine-Badge-root {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
}

/* Fix for menu items with icon alignment */
.mantine-Menu-item {
  display: flex !important;
  align-items: center !important;
}

/* Ensure text doesn't overflow in menu items */
.mantine-Text-root {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Enhance the chevron styling */
.mantine-Menu-itemRightSection {
  margin-left: auto !important;
  display: flex !important;
  align-items: center !important;
}

/* Fix for sub-menu padding */
.mantine-Menu-dropdown {
  padding: 6px !important;
}

/* Current active state styling */
.mantine-UnstyledButton-root.active {
  font-weight: 600 !important;
}

/* User greeting styles */
.user-greeting-button {
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  max-width: 200px;
}

.user-greeting-button:hover {
  background-color: var(--primary-light) !important;
}

.user-greeting-button:hover .user-greeting-icon {
  transform: scale(1.1);
}

.user-greeting-button:active {
  transform: translateY(1px);
}

/* Username text styles */
.user-greeting-button .mantine-Text-root {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  display: block;
}

/* Add a subtle badge to the user icon for premium users */
.user-greeting-premium .user-greeting-icon::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  background-color: gold;
  border-radius: 50%;
  border: 1px solid white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add CSS to properly display article images */
.article-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto; /* Changed from 20px 0 to 20px auto to center images */
  border-radius: 4px;
  object-fit: contain; /* Added to ensure images are fully displayed */
}

/* Hide alt text that might be displayed as separate text */
.article-body img + br + img[alt] {
  font-size: 0;
}

/* Fix for any CSS issues with alt text display */
.article-body img[alt]:after {
  display: none !important;
}

/* Enhance the overall article presentation */
.article-content {
  font-family: 'Georgia', serif;
  line-height: 1.6;
  color: var(--text-color);
  font-size: 16px;
  width: 100%;
  max-width: 800px; /* Reduced from 1000px to 800px for better readability */
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.article-content h1 {
  font-size: 2.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: var(--header-color);
  font-weight: 700;
}

.article-content h2 {
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: var(--header-color);
  font-weight: 600;
}

.article-content h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--header-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
  font-weight: 600;
}

[data-mantine-color-scheme="dark"] .article-content h1,
[data-mantine-color-scheme="dark"] .article-content h2,
[data-mantine-color-scheme="dark"] .article-content h3 {
  color: var(--primary-light);
  border-color: var(--border-color);
}

.article-content p {
  margin-bottom: 1.5rem;
  text-align: justify;
  padding: 0;
  font-size: 1.05rem;
  line-height: 1.7;
}

.article-content h3 + p {
  text-indent: 0;
  font-weight: 500;
}

.article-content p:last-child {
  margin-bottom: 2rem;
}

.article-content strong, 
.article-content b {
  color: var(--header-color);
  font-weight: 600;
}

.article-content > * {
  max-width: 100%;
}

/* Article detail page specific styles */
.article-detail-container {
  padding-top: 24px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
}

.article-detail-container .article-title {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 16px;
  color: var(--header-color);
}

.article-detail-container .article-meta {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  color: var(--text-muted);
  font-size: 0.9rem;
}

.article-detail-container .article-meta > * {
  margin-right: 16px;
}

.article-detail-container .article-image {
  width: 100%;
  max-height: none; /* Removed max-height constraint to show full image */
  object-fit: contain; /* Changed from cover to contain to show the entire image */
  border-radius: 8px;
  margin-bottom: 24px;
}

.article-detail-container .article-content {
  padding: 0;
}

@media (max-width: 768px) {
  .article-detail-container {
    padding: 16px 12px;
  }
  
  .article-detail-container .article-title {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }
  
  .article-detail-container .article-meta {
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  
  .article-detail-container .article-meta > * {
    margin-right: 12px;
    margin-bottom: 8px;
  }
  
  .article-content p {
    text-align: left;
    font-size: 1rem;
  line-height: 1.6;
}

  .article-content h1 {
    font-size: 1.8rem;
  }
  
  .article-content h2 {
    font-size: 1.5rem;
  }
  
  .article-content h3 {
    font-size: 1.3rem;
  }
}

/* App shell and navbar styling */
.mantine-AppShell-root {
  overflow-x: hidden !important;
}

.mantine-AppShell-root .mantine-AppShell-navbar {
  z-index: 300 !important;
  position: fixed !important;
  top: 70px !important; /* Match header height */
  height: calc(100vh - 70px) !important;
}

/* Article list container specific styles */
.article-list-container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 !important;
  position: relative;
  min-height: calc(100vh - 70px);
  /* background-color: var(--background-color); */
  padding-top: 0 !important; /* Remove top padding to prevent extra space */
}

/* Remove padding from content-container when it contains article-list-container */
.content-container .article-list-container {
  margin: 0 auto !important;
  padding: 0 !important;
  padding-top: 0 !important;
  width: 100% !important;
  max-width: 1200px !important;
}

@media (min-width: 769px) {
  .mantine-AppShell-root:not([data-navbar-collapsed="true"]) .article-list-container {
    margin-left: 200px !important;
    width: calc(100% - 200px) !important;
    max-width: none !important;
    padding: 0 !important;
    padding-top: 0 !important;
  }
  
  .mantine-AppShell-root[data-navbar-collapsed="true"] .article-list-container {
    margin-left: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    padding-top: 0 !important;
  }
  
  /* Improve card grid spacing */
  /* When sidebar is open, add margin to content */
  .mantine-AppShell-root:not([data-navbar-collapsed="true"]) .content-container {
    margin-left: 200px !important;
    width: calc(100% - 250px) !important;
  }
  
  /* Remove padding from content-container when it contains article-list-container */
  .mantine-AppShell-root:not([data-navbar-collapsed="true"]) .content-container .article-list-container {
    margin-left: 0 !important;
    width: 100% !important;
    padding: 0 !important;
    padding-top: 0 !important;
  }
  
  /* Add subtle background pattern for desktop */
  .mantine-AppShell-main {
    background-image: 
      linear-gradient(rgba(236, 237, 253, 0.3) 1px, transparent 1px),
      linear-gradient(90deg, rgba(236, 237, 253, 0.3) 1px, transparent 1px);
    background-size: 20px 20px;
    background-position: center center;
  }
  
  /* Dark mode background pattern */
  [data-mantine-color-scheme="dark"] .mantine-AppShell-main {
    background-image: 
      linear-gradient(rgba(92, 124, 250, 0.05) 1px, transparent 1px),
      linear-gradient(90deg, rgba(92, 124, 250, 0.05) 1px, transparent 1px);
  }
  
  /* Card styling improvements for desktop */
  .mantine-Card-root {
    border-radius: 12px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
    transition: transform 0.2s ease, box-shadow 0.2s ease !important;
  }
  
  .mantine-Card-root:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08) !important;
  }
  
  [data-mantine-color-scheme="dark"] .mantine-Card-root {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  }
  
  [data-mantine-color-scheme="dark"] .mantine-Card-root:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25) !important;
  }
}

/* Specific adjustment for UserSettings to reduce gap */
.mantine-AppShell-main [data-view="userSettings"] .content-container {
  padding-top: 0;
}

/* Specific adjustment for UserSettings Box component */
[data-view="userSettings"] .mantine-Box-root {
  margin-top: 0;
  padding-top: 0;
}

/* Additional UserSettings specific styles */
[data-view="userSettings"] .mantine-TextInput-root,
[data-view="userSettings"] .mantine-PasswordInput-root,
[data-view="userSettings"] .mantine-DateTimePicker-root {
  margin-bottom: 12px;
}

[data-view="userSettings"] .mantine-Title-root {
  margin-bottom: 12px;
}

[data-view="userSettings"] .mantine-Alert-root {
  margin-bottom: 12px;
}

/* Page title styles */
.mantine-Title-root {
  margin-top: 8px;
  margin-bottom: 16px;
}

/* Specific title styles for different levels */
h1.mantine-Title-root {
  margin-top: 8px;
  margin-bottom: 20px;
}

h2.mantine-Title-root {
  margin-top: 8px;
  margin-bottom: 16px;
}

h3.mantine-Title-root, 
h4.mantine-Title-root {
  margin-top: 8px;
  margin-bottom: 12px;
}

/* Specifically target active state indicators */
.login-nav-item.active::before,
.login-nav-item.active::after,
.nav-item[class*="Login"].active::before,
.nav-item[class*="Login"].active::after,
.mantine-UnstyledButton-root.login-nav-item.active::before,
.mantine-UnstyledButton-root.login-nav-item.active::after,
.mantine-UnstyledButton-root.nav-item[class*="Login"].active::before,
.mantine-UnstyledButton-root.nav-item[class*="Login"].active::after {
  display: none !important;
  content: none !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  border: none !important;
  background: none !important;
  position: static !important;
}

/* Ensure no left border on any navigation items */
.desktop-navbar .nav-items-container .mantine-UnstyledButton-root,
.mobile-bottom-nav .nav-items-container .mantine-UnstyledButton-root {
  border-left: none !important;
}

/* Global override to remove all blue line indicators */
.mantine-AppShell-navbar *::before,
.mantine-AppShell-navbar *::after,
.desktop-navbar *::before,
.desktop-navbar *::after,
.mobile-bottom-nav *::before,
.mobile-bottom-nav *::after,
.nav-item::before,
.nav-item::after,
.login-nav-item::before,
.login-nav-item::after,
.nav-items-container *::before,
.nav-items-container *::after {
  display: none !important;
  content: none !important;
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  background: none !important;
  position: static !important;
}

/* Remove any border-left from all navigation elements */
.mantine-AppShell-navbar *,
.desktop-navbar *,
.mobile-bottom-nav *,
.nav-item,
.login-nav-item,
.nav-items-container * {
  border-left: none !important;
}

/* Override for header background color to match buttons exactly */
@media (max-width: 750px) {
  .mantine-AppShell-header {
    background-color: #f0f2f5 !important;
  }
  
  [data-mantine-color-scheme="dark"] .mantine-AppShell-header {
    background-color: #3E4047 !important;
  }
}

/* Override for the request-access-button in mobile view to match desktop design */
@media (max-width: 768px) {
  .request-access-button {
    background-color: #4c6ef5 !important;
    color: white !important;
    border-radius: 50% !important;
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    overflow: hidden !important;
  }
  
  .request-access-button .mantine-Button-leftIcon {
    margin-right: 0 !important;
    margin: 0 auto !important;
  }
  
  .request-access-button .mantine-Button-leftIcon svg {
    width: 18px !important;
    height: 18px !important;
    color: white !important;
    display: block !important;
  }
  
  [data-mantine-color-scheme="dark"] .request-access-button {
    background-color: #4c6ef5 !important;
    color: white !important;
    border-radius: 50% !important;
    overflow: hidden !important;
  }
  
  [data-mantine-color-scheme="dark"] .request-access-button:hover {
    background-color: #5d7bf7 !important;
    border-radius: 50% !important;
  }
  
  .request-access-button:hover {
    background-color: #5d7bf7 !important;
    transform: translateY(-1px) !important;
    border-radius: 50% !important;
  }
  
  /* Fix for theme toggle button on mobile */
  .theme-toggle-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

/* Ensure main content is below the header in mobile view */
@media (max-width: 768px) {
  .mantine-AppShell-main {
    padding: 0 !important;
    margin: 0 !important;
    padding-top: 60px !important;
    padding-bottom: 80px !important;
  }
  
  .content-container {
    padding: 8px 12px !important;
    margin: 0 !important;
    width: 100% !important;
    border: none !important;
  }
}

/* Mobile specific styles for article list */
@media (max-width: 768px) {
  .article-list-container {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  
  .article-list-container .section-title {
    padding: 8px 12px 0 12px !important;
    margin-bottom: 12px !important;
  }
  
  .article-list-container .mantine-SimpleGrid-root {
    margin: 0 12px !important;
    padding: 0 !important;
  }
  
  .article-list-container .mantine-Group-root {
    padding: 0 12px 16px 12px !important;
  }
  
  .article-list-container .empty-state-container {
    margin: 0 12px !important;
  }
}

/* Global fix for all icons in the header */
.mantine-AppShell-header .mantine-ThemeIcon-root,
.mantine-AppShell-header .mantine-ActionIcon-root,
.mantine-AppShell-header .theme-toggle-button,
.mantine-AppShell-header .request-access-button,
.mantine-AppShell-header .user-greeting-button .user-greeting-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  overflow: hidden !important;
}

.mantine-AppShell-header .mantine-ThemeIcon-root svg,
.mantine-AppShell-header .mantine-ActionIcon-root svg {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Fix for any square containers that might be wrapping the icons */
.mantine-AppShell-header .mantine-ActionIcon-root > *,
.mantine-AppShell-header .mantine-ThemeIcon-root > *,
.mantine-AppShell-header .theme-toggle-button > *,
.mantine-AppShell-header .request-access-button > * {
  border-radius: 50% !important;
}

/* Ensure the button inner elements don't have sharp corners */
.mantine-AppShell-header .mantine-Button-inner,
.mantine-AppShell-header .mantine-ActionIcon-inner {
  border-radius: inherit !important;
}

/* Ensure the SVG icons inside buttons don't have sharp corners */
.mantine-AppShell-header svg {
  border-radius: 0 !important;
}

/* Reset all header icon styles */
.mantine-AppShell-header .mantine-ThemeIcon-root,
.mantine-AppShell-header .mantine-ActionIcon-root,
.mantine-AppShell-header .theme-toggle-button,
.mantine-AppShell-header .request-access-button,
.mantine-AppShell-header .user-greeting-button .user-greeting-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  overflow: hidden !important;
}

/* Ensure circular icons are actually circular */
.mantine-AppShell-header .theme-toggle-button,
.mantine-AppShell-header .request-access-button,
.mantine-AppShell-header .user-greeting-button .user-greeting-icon {
  border-radius: 50% !important;
}

/* Special styling for app logo icon */
.mantine-AppShell-header .app-logo-icon {
  border-radius: 8px !important;
  background: linear-gradient(45deg, #6e56cf, #4c6ef5) !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Center all SVG icons within their containers */
.mantine-AppShell-header .mantine-ThemeIcon-root svg,
.mantine-AppShell-header .mantine-ActionIcon-root svg,
.mantine-AppShell-header .theme-toggle-button svg,
.mantine-AppShell-header .request-access-button svg,
.mantine-AppShell-header .user-greeting-button .user-greeting-icon svg,
.mantine-AppShell-header .app-logo-icon svg {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .mantine-AppShell-header .app-logo-icon {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
  }
  
  .mantine-AppShell-header .app-logo-icon svg {
    width: 18px !important;
    height: 18px !important;
  }
  
  .mantine-AppShell-header .theme-toggle-button,
  .mantine-AppShell-header .request-access-button {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
  }
}

/* Dark mode styles for submenu items */
[data-mantine-color-scheme="dark"] .accordion-menu-subitem .mantine-UnstyledButton-root {
  color: var(--text-light);
}

[data-mantine-color-scheme="dark"] .accordion-menu-subitem .mantine-UnstyledButton-root:hover {
  background-color: var(--primary-dark);
  color: white;
  font-weight: 600;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.accordion-menu-subitem .mantine-UnstyledButton-root:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1px;
  width: 15px;
  height: 100%;
  background-color: var(--mantine-color-gray-1);
}

/* Ensure the Card component has the correct border radius */
.article-card.mantine-Card-root {
  border-radius: 8px !important;
  overflow: hidden !important;
}

/* Ensure the Card.Section has the correct border radius */
.article-card .mantine-Card-section {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  overflow: hidden !important;
}

/* Ensure all image elements have the correct border radius */
.article-card .mantine-Image-root,
.article-card .mantine-Image-figure,
.article-card .mantine-Image-imageWrapper,
.article-card .mantine-Image-image {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.article-card .mantine-Card-section:first-child {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  overflow: hidden !important;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0 !important;
}

/* Fix for Mantine Image component */
.article-card .mantine-Image-root {
  width: 100%;
  height: 100%;
}

.article-card .mantine-Image-figure {
  width: 100%;
  height: 100%;
}

.article-card .mantine-Image-imageWrapper {
  width: 100%;
  height: 100%;
}

.article-card .mantine-Image-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain !important;
}

/* Quill Editor Styles */
.ql-editor {
  min-height: 300px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.ql-editor h1,
.ql-editor h2,
.ql-editor h3 {
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.ql-editor h1 {
  font-size: 2em;
}

.ql-editor h2 {
  font-size: 1.5em;
}

.ql-editor h3 {
  font-size: 1.17em;
}

.ql-editor p {
  margin-bottom: 1em;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1em auto;
}

.ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin: 1.5em 0;
  padding-left: 1em;
  font-style: italic;
}

.ql-editor ul,
.ql-editor ol {
  padding-left: 1.5em;
  margin-bottom: 1em;
}

.ql-editor li {
  margin-bottom: 0.5em;
}

.ql-editor a {
  color: #228be6;
  text-decoration: none;
}

.ql-editor a:hover {
  text-decoration: underline;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: #ced4da;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #ced4da;
}

/* Dark mode styles */
[data-mantine-color-scheme="dark"] .ql-toolbar.ql-snow,
[data-mantine-color-scheme="dark"] .ql-container.ql-snow {
  border-color: #373A40;
}

[data-mantine-color-scheme="dark"] .ql-editor {
  color: #C1C2C5;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-stroke {
  stroke: #C1C2C5;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-fill {
  fill: #C1C2C5;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-picker {
  color: #C1C2C5;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-picker-options {
  background-color: #25262B;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-picker-label {
  background-color: #25262B;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-tooltip {
  background-color: #25262B;
  color: #C1C2C5;
}

[data-mantine-color-scheme="dark"] .ql-snow .ql-tooltip input[type=text] {
  background-color: #373A40;
  color: #C1C2C5;
  border-color: #4A4D52;
}
