/* Admin Dashboard Mobile Styles */

/* Base styles for the admin dashboard container */
.admin-dashboard-container {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  position: relative !important;
  z-index: 1 !important;
}

/* Tabs styling */
.admin-dashboard-container .mantine-Tabs-root {
  width: 100% !important;
}

/* Tab list scrolling for mobile */
.admin-dashboard-container .mantine-Tabs-list {
  overflow-x: auto !important;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* IE and Edge */
  white-space: nowrap !important;
  padding: 0 12px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.admin-dashboard-container .mantine-Tabs-list::-webkit-scrollbar {
  display: none !important;
}

/* Individual tab styling */
.admin-dashboard-container .mantine-Tabs-tab {
  padding: 12px 16px !important;
  font-size: 14px !important;
  min-height: 42px !important;
}

/* Table container styles */
.admin-table-container {
  width: 100% !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Table styles */
.admin-table {
  width: 100% !important;
  min-width: 800px !important; /* Ensure table doesn't get too squished */
  border-collapse: collapse !important;
}

/* Table header and cell styles */
.admin-table th,
.admin-table td {
  padding: 12px 16px !important;
  font-size: 14px !important;
  text-align: left !important;
  white-space: nowrap !important;
}

/* Action buttons in tables */
.admin-table .action-buttons {
  display: flex !important;
  gap: 8px !important;
  flex-wrap: nowrap !important;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .admin-dashboard-container {
    padding: 12px !important;
  }

  /* Adjust title spacing */
  .admin-dashboard-container .mantine-Title-root {
    font-size: 1.5rem !important;
    margin-bottom: 16px !important;
    padding: 0 !important;
  }

  /* Tab panel padding */
  .admin-dashboard-container .mantine-Tabs-panel {
    padding: 16px 0 !important;
  }

  /* Make cards and papers full width */
  .admin-dashboard-container .mantine-Paper-root,
  .admin-dashboard-container .mantine-Card-root {
    width: 100% !important;
    margin: 0 !important;
    padding: 12px !important;
    border-radius: 8px !important;
  }

  /* Adjust form inputs */
  .admin-dashboard-container .mantine-TextInput-root,
  .admin-dashboard-container .mantine-Select-root,
  .admin-dashboard-container .mantine-Textarea-root {
    margin-bottom: 16px !important;
  }

  /* Style form buttons */
  .admin-dashboard-container .mantine-Button-root {
    width: 100% !important;
    margin-bottom: 8px !important;
  }

  /* Add horizontal scroll indicators */
  .admin-table-container::before,
  .admin-table-container::after {
    content: "" !important;
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 20px !important;
    pointer-events: none !important;
    z-index: 1 !important;
  }

  .admin-table-container::before {
    left: 0 !important;
    background: linear-gradient(to right, rgba(255,255,255,0.9), rgba(255,255,255,0)) !important;
  }

  .admin-table-container::after {
    right: 0 !important;
    background: linear-gradient(to left, rgba(255,255,255,0.9), rgba(255,255,255,0)) !important;
  }

  /* Dark mode scroll indicators */
  [data-mantine-color-scheme="dark"] .admin-table-container::before {
    background: linear-gradient(to right, rgba(26,27,30,0.9), rgba(26,27,30,0)) !important;
  }

  [data-mantine-color-scheme="dark"] .admin-table-container::after {
    background: linear-gradient(to left, rgba(26,27,30,0.9), rgba(26,27,30,0)) !important;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .admin-dashboard-container {
    padding: 8px !important;
  }

  /* Further reduce text sizes */
  .admin-dashboard-container .mantine-Title-root {
    font-size: 1.25rem !important;
  }

  .admin-table th,
  .admin-table td {
    padding: 8px 12px !important;
    font-size: 13px !important;
  }

  /* Stack action buttons in extra small screens */
  .admin-table .action-buttons {
    flex-direction: column !important;
    gap: 4px !important;
  }

  .admin-table .action-buttons .mantine-Button-root {
    padding: 4px 8px !important;
    font-size: 12px !important;
  }
}

/* Ensure iOS safe area is respected */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .admin-dashboard-container {
    padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
  }
} 