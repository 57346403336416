.zodiac-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.zodiac-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--mantine-shadow-lg);
}

.zodiac-symbol {
  font-size: 64px;
  line-height: 1;
  text-align: center;
  background: linear-gradient(45deg, var(--mantine-color-blue-5), var(--mantine-color-violet-5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.zodiac-element-fire {
  background-color: var(--mantine-color-red-0);
  color: var(--mantine-color-red-9);
}

.zodiac-element-earth {
  background-color: var(--mantine-color-green-0);
  color: var(--mantine-color-green-9);
}

.zodiac-element-air {
  background-color: var(--mantine-color-blue-0);
  color: var(--mantine-color-blue-9);
}

.zodiac-element-water {
  background-color: var(--mantine-color-cyan-0);
  color: var(--mantine-color-cyan-9);
}

.zodiac-trait-badge {
  transition: transform 0.2s ease;
}

.zodiac-trait-badge:hover {
  transform: scale(1.05);
}

.zodiac-horoscope-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--mantine-color-gray-8);
}

.zodiac-timeframe-select {
  min-width: 120px;
}

@media (max-width: 768px) {
  .zodiac-symbol {
    font-size: 48px;
  }
  
  .zodiac-horoscope-content {
    font-size: 1rem;
  }
  
  .zodiac-timeframe-select {
    width: 100%;
    margin-top: 1rem;
  }
} 