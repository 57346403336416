/* Horoscope Styling - Main Container */
.horoscope-content {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  font-size: 16px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

/* Apply article styling to all horoscope text content */
#horoscope-content,
div[id^="horoscope-content-"],
.horoscope-content p,
.horoscope-content .mantine-Text-root,
.horoscope-content .mantine-Paper-root,
.saved-horoscope-container,
.horoscope-detail-container,
div[class*="horoscope"] .mantine-Text-root {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.6;
}

/* Horoscope titles styling */
div[class*="horoscope"] .mantine-Title-root,
.horoscope-content .mantine-Title-root,
.saved-horoscope-container .mantine-Title-root {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: var(--header-color);
  font-weight: 600;
}

/* Heading styling */
.horoscope-content h1,
div[class*="horoscope"] h1 {
  font-size: 2.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  color: var(--header-color);
  font-weight: 700;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.horoscope-content h2,
div[class*="horoscope"] h2 {
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  color: var(--header-color);
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.horoscope-content h3,
div[class*="horoscope"] h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--header-color);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Paragraph styling */
.horoscope-content p,
div[class*="horoscope"] p,
.saved-horoscope-container div {
  margin-bottom: 1.5rem;
  text-align: justify;
  padding: 0;
  font-size: 1.05rem;
  line-height: 1.7;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Style for emphasized text */
.horoscope-content strong, 
.horoscope-content b,
div[class*="horoscope"] strong,
div[class*="horoscope"] b {
  color: var(--header-color);
  font-weight: 600;
}

/* Dark mode adjustments */
[data-mantine-color-scheme="dark"] .horoscope-content h1,
[data-mantine-color-scheme="dark"] .horoscope-content h2,
[data-mantine-color-scheme="dark"] .horoscope-content h3,
[data-mantine-color-scheme="dark"] div[class*="horoscope"] h1,
[data-mantine-color-scheme="dark"] div[class*="horoscope"] h2,
[data-mantine-color-scheme="dark"] div[class*="horoscope"] h3 {
  color: var(--primary-light);
  border-color: var(--border-color);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .horoscope-content p,
  div[class*="horoscope"] p,
  .saved-horoscope-container div {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
  }

  .horoscope-content h1,
  div[class*="horoscope"] h1 {
    font-size: 1.8rem;
  }
  
  .horoscope-content h2,
  div[class*="horoscope"] h2 {
    font-size: 1.5rem;
  }
  
  .horoscope-content h3,
  div[class*="horoscope"] h3 {
    font-size: 1.3rem;
  }
}

/* Apply styling to SavedHoroscopeDisplay component */
.saved-horoscope-container {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

/* Style specific components in the HoroscopePage component */
.mantine-Paper-root [id^="horoscope-content-"] {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.05rem;
  line-height: 1.7;
  padding: 1rem;
  color: var(--text-color);
}

/* Additional styling for horoscope components */
.horoscope-section-title {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  color: var(--header-color) !important;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

/* Consistent styling for all horoscope Paper components */
.horoscope-paper, 
.horoscope-content .mantine-Paper-root {
  width: 100% !important;
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 16px !important;
}

.horoscope-detail-item {
  margin-bottom: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.05rem;
  line-height: 1.7;
}

.horoscope-detail-label {
  font-weight: 600;
  color: var(--header-color);
  margin-right: 6px;
}

/* Fix for Titles within horoscope content */
.horoscope-content .mantine-Title-root {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

/* Make sure text in Horoscope paper components also uses the right font */
.horoscope-paper .mantine-Text-root,
.horoscope-paper p {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.05rem;
  line-height: 1.7;
}

[data-mantine-color-scheme="dark"] .horoscope-section-title {
  color: var(--primary-light) !important;
}

/* Styling for charts and graphs in horoscope components */
.horoscope-content .recharts-wrapper,
.horoscope-chart-container {
  margin: 2rem auto;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.horoscope-content .recharts-text {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  fill: var(--text-color);
}

.horoscope-content .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: var(--background-color) !important;
  border-color: var(--border-color) !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.horoscope-content .recharts-default-tooltip .recharts-tooltip-label {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  color: var(--header-color) !important;
}

.horoscope-content .recharts-responsive-container {
  width: 100% !important;
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Dark mode adjustments for charts */
[data-mantine-color-scheme="dark"] .horoscope-content .recharts-text {
  fill: #c1c2c5;
}

[data-mantine-color-scheme="dark"] .horoscope-content .recharts-cartesian-grid line {
  stroke: rgba(255, 255, 255, 0.1);
}

[data-mantine-color-scheme="dark"] .horoscope-content .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #25262b !important;
  border-color: #373a40 !important;
}

[data-mantine-color-scheme="dark"] .horoscope-content .recharts-default-tooltip .recharts-tooltip-label {
  color: #c1c2c5 !important;
}

/* Styling for specific elements */
.horoscope-detail-note {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-light);
  margin-bottom: 1.5rem;
}

.horoscope-main-content {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 1rem 0;
}

/* Article-style heading for horoscopes */
.horoscope-content .mantine-Title-root[order="3"],
.horoscope-paper .mantine-Title-root[order="3"]:first-of-type {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 2.75rem !important;
  font-weight: 800 !important;
  line-height: 1.1 !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1rem !important;
  color: #7582ff !important; /* Change to blue color as in the screenshot */
  letter-spacing: -0.5px !important;
}

/* Article header meta information */
.horoscope-meta {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  color: #666;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.horoscope-meta > span {
  margin-right: 1rem;
}

/* Style for subtitle under main horoscope title */
.horoscope-subtitle {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
  margin-bottom: 2rem !important;
  color: #333333 !important;
  font-weight: 400 !important;
}

[data-mantine-color-scheme="dark"] .horoscope-subtitle {
  color: #b0b0b0 !important;
}

/* Dark mode adjustments for horoscope heading */
[data-mantine-color-scheme="dark"] .horoscope-content .mantine-Title-root[order="3"],
[data-mantine-color-scheme="dark"] .horoscope-paper .mantine-Title-root[order="3"]:first-of-type,
[data-mantine-color-scheme="dark"] .horoscope-main-title {
  color: #7582ff !important; /* Keep blue color in dark mode too */
  border-bottom: 1px solid rgba(117, 130, 255, 0.3) !important;
}

/* Special styling for main horoscope title */
.horoscope-main-title {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 1.75rem !important;
  font-weight: 800 !important;
  line-height: 1.1 !important;
  margin-bottom: 1rem !important;
  margin-top: 0.5rem !important;
  color: #7582ff !important; /* Change to blue color as in the screenshot */
  letter-spacing: -0.5px !important;
}

[data-mantine-color-scheme="dark"] .horoscope-main-title {
  color: #8e99ff !important; /* Lighter blue for dark mode */
}

/* Style for date format shown in screenshot (03/18/2025) */
.horoscope-date {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  font-size: 0.95rem !important;
  color: #777777 !important;
  margin-top: -0.75rem !important;
  margin-bottom: 1.5rem !important;
}

[data-mantine-color-scheme="dark"] .horoscope-date {
  color: #aaaaaa !important;
}

/* Responsive adjustments for horoscope title */
@media (max-width: 768px) {
  .horoscope-main-title {
    font-size: 2rem !important;
  }
  
  .horoscope-subtitle {
    font-size: 1rem;
  }
  
  .horoscope-date {
    font-size: 1.5rem !important;
  }
}

/* Style horoscope form */
.horoscope-form {
  border-radius: 12px;
  background: rgba(240, 240, 240, 0.3);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  padding: 1.5rem;
}

[data-mantine-color-scheme="dark"] .horoscope-form {
  background: rgba(30, 30, 30, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.05);
} 