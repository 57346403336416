/* ======================================================
   MOBILE SUBMENU FIX - CLEAN SLATE APPROACH
   ====================================================== */

/* 
 * Hide all text in mobile navigation
 */
@media (max-width: 768px) {
  .mobile-bottom-nav .mantine-Text-root,
  .mobile-bottom-nav .nav-item .mantine-Text-root,
  .mobile-bottom-nav .accordion-menu-item .mantine-Text-root {
    display: none !important;
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
    overflow: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/* 
 * SUBMENU BACKDROP
 * This is the semi-transparent overlay that appears behind the submenu
 */
.mobile-submenu-backdrop {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 60px !important;
  z-index: 9998 !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  backdrop-filter: blur(4px) !important;
  animation: fadeIn 0.3s ease-out !important;
}

/* 
 * SUBMENU CONTAINER
 * This is the main container for the submenu
 */
.mobile-submenu {
  position: fixed !important;
  bottom: 60px !important;
  left: 0 !important;
  right: 0 !important;
  width: 100% !important; /* Full width */
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 20px 20px 0 0 !important;
  background-color: white !important;
  box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.15) !important;
  z-index: 9999 !important;
  animation: slideUp 0.3s ease-out !important;
  max-height: 75vh !important;
  overflow-y: auto !important;
}

/* iOS safe area support */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
  .mobile-submenu {
    bottom: calc(60px + env(safe-area-inset-bottom)) !important;
  }
}

/* 
 * SUBMENU HANDLE
 * This is the small bar at the top of the submenu
 */
.mobile-submenu-handle {
  content: "" !important;
  position: absolute !important;
  top: 8px !important;
  left: 50% !important;
  width: 40px !important;
  height: 4px !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 2px !important;
  transform: translateX(-50%) !important;
}

/* 
 * SUBMENU TITLE
 * This is the title at the top of the submenu
 */
.mobile-submenu .options-title {
  width: 100% !important;
  padding: 15px 0 !important;
  margin: 0 !important;
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
  color: #4c6ef5 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

/* 
 * SUBMENU GRID
 * This is the container for all submenu items
 */
.mobile-submenu .submenu-grid {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
}

/* 
 * SUBMENU ITEM
 * This styles each individual submenu item
 */
.mobile-submenu .submenu-item {
  width: 100% !important;
  height: 68px !important;
  margin: 0 !important;
  padding: 0 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  background-color: white !important;
  cursor: pointer !important;
  text-align: left !important;
  transition: background-color 0.2s ease !important;
}

.mobile-submenu .submenu-item:hover,
.mobile-submenu .submenu-item:active {
  background-color: rgba(76, 110, 245, 0.03) !important;
}

/* 
 * SUBMENU ICON
 * This styles the icon container in each submenu item
 */
.mobile-submenu .submenu-icon {
  width: 42px !important;
  height: 42px !important;
  min-width: 42px !important;
  min-height: 42px !important;
  margin-right: 16px !important;
  border-radius: 50% !important;
  background-color: rgba(76, 110, 245, 0.08) !important;
  border: 1px solid rgba(76, 110, 245, 0.2) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  overflow: visible !important;
  box-shadow: none !important;
  transition: all 0.2s ease !important;
}

/* Ensure icons inside the submenu icon container are properly sized */
.mobile-submenu .submenu-icon svg {
  width: 22px !important;
  height: 22px !important;
  display: block !important;
  margin: 0 auto !important;
  padding: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/* 
 * SUBMENU LABEL
 * This styles the text label in each submenu item
 */
.mobile-submenu .submenu-label {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333 !important;
  transition: color 0.2s ease !important;
}

/* 
 * ACTIVE SUBMENU ITEM
 * This styles the currently active submenu item
 */
.mobile-submenu .active-submenu-item {
  background-color: rgba(76, 110, 245, 0.05) !important;
  border-left: 6px solid #4c6ef5 !important;
  padding-left: 14px !important;
}

.mobile-submenu .active-submenu-item .submenu-icon {
  background-color: rgba(76, 110, 245, 0.15) !important;
  border: 1px solid rgba(76, 110, 245, 0.4) !important;
  transform: scale(1.05) !important;
  box-shadow: 0 2px 4px rgba(76, 110, 245, 0.1) !important;
}

.mobile-submenu .active-submenu-item .submenu-label {
  font-weight: 600 !important;
  color: #4c6ef5 !important;
}

/* 
 * DARK MODE ADJUSTMENTS
 * These target the dark mode styles for the submenu
 */
[data-mantine-color-scheme="dark"] .mobile-submenu {
  background-color: #1A1B1E !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-item {
  background-color: #1A1B1E !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-item:hover,
[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-item:active {
  background-color: rgba(92, 124, 250, 0.5) !important;
  color: #ffffff !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-item:hover .submenu-label,
[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-item:active .submenu-label {
  color: #ffffff !important;
  font-weight: 600 !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-label {
  color: #fff !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .submenu-icon {
  background-color: rgba(92, 124, 250, 0.1) !important;
  border: 1px solid rgba(92, 124, 250, 0.3) !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .active-submenu-item .submenu-icon {
  background-color: rgba(92, 124, 250, 0.2) !important;
  border: 1px solid rgba(92, 124, 250, 0.5) !important;
}

[data-mantine-color-scheme="dark"] .mobile-submenu .active-submenu-item .submenu-label {
  color: #5c7cfa !important;
}

/* 
 * SCROLLBAR STYLING
 * Custom styling for the submenu scrollbar
 */
.mobile-submenu::-webkit-scrollbar {
  width: 5px !important;
}

.mobile-submenu::-webkit-scrollbar-thumb {
  background-color: rgba(134, 142, 150, 0.3) !important;
  border-radius: 10px !important;
}

.mobile-submenu::-webkit-scrollbar-track {
  background-color: transparent !important;
}

/* 
 * ANIMATIONS
 * These define the animations used by the submenu
 */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
 * ACTIVE ITEM PULSE ANIMATION
 * Subtle pulse animation for the active item indicator
 */
.mobile-submenu .active-submenu-item::before {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 6px !important;
  height: 60% !important;
  background-color: #4c6ef5 !important;
  border-radius: 0 3px 3px 0 !important;
  animation: pulseIndigo 1.5s infinite ease-in-out !important;
}

@keyframes pulseIndigo {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

/* Submenu item styling */
.submenu-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 8px;
}

.submenu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark-mode .submenu-item:hover {
  background-color: rgba(92, 124, 250, 0.5);
  color: #ffffff;
}

.dark-mode .submenu-item:hover .submenu-label {
  color: #ffffff !important;
  font-weight: 600 !important;
}

/* Submenu item icon styling */
.submenu-item-icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 42px !important;
  height: 42px !important;
  min-width: 42px !important;
  min-height: 42px !important;
  max-width: 42px !important;
  max-height: 42px !important;
  margin-right: 16px !important;
  border-radius: 50% !important;
  background-color: rgba(236, 237, 253, 0.08) !important;
  border: 1px solid rgba(144, 146, 150, 0.5) !important;
  transition: all 0.2s ease !important;
  position: relative !important;
  box-sizing: border-box !important;
  overflow: visible !important;
}

/* Ensure icons inside the submenu icon container are properly sized and centered */
.submenu-item-icon svg {
  width: 22px !important;
  height: 22px !important;
  display: block !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/* Active submenu item styling */
.submenu-item.active .submenu-item-icon {
  background-color: rgba(236, 237, 253, 0.15);
  border: 1px solid rgba(92, 124, 250, 0.4);
  box-shadow: 0 2px 4px rgba(92, 124, 250, 0.1);
} 